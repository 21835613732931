
<div>
  <div class="flex justify-between">
    <div class="flex items-center">
      <input
        :id="`ml-map-${map.id}`"
        :checked="isInCurrentMaps"
        :disabled="fetchingData"
        type="checkbox"
        class="appearance-none h-6 w-6 border border-gray-300 rounded bg-white checked:bg-app checked:border-app focus:outline-none align-top mr-2 cursor-pointer"
        @input="toggleCurrentMaps"
      >
      <label
        class="inline-block select-none cursor-pointer dark:text-white flex-1"
        :for="`ml-map-${map.id}`"
      >
        {{ map.name }}
      </label>
    </div>
    <div
      v-if="fetchingData"
      class="text-app"
    >
      <fa-icon
        icon="fa-solid fa-sync"
        spin
      />
    </div>
  </div>
</div>
