
<div class="h-full w-full">
  <div
    v-if="true"
    class="flex h-full w-full flex-col md:flex-row"
  >
    <l-map
      ref="map"
      class="h-full w-full map"
      :zoom="mapZoom"
      :min-zoom="minZoom"
      :center="mapCenter"
      :options="{ preferCanvas: true, zoomControl: false }"
      @ready="mapMounted"
    >
      <l-tile-layer
        :url="tileLayerUrl"
        :attribution="mapAttribution"
      />
      <l-control
        position="bottomleft"
      >
        <MapControls
          v-if="mapObject"
          :map-object="mapObject"
        />
      </l-control>
      <l-control
        position="topright"
      >
        <div class="text-app flex flex-row gap-2">
          <router-link
            :to="{ name: 'Funding' }"
            class="bg-white p-2 border-app border border-2 shadow rounded hover:outline outline-2 outline-offset-1 dark:bg-gray-700 sm:block hidden"
          >
            <fa-icon
              icon="fa-solid fa-beer"
              fixed-width
            />
          </router-link>
          <a
            href="#"
            class="bg-white p-2 border-app border border-2 shadow rounded hover:outline outline-2 outline-offset-1 dark:bg-gray-700 sm:block hidden"
            @click.prevent="toggleColorScheme"
          >
            <fa-icon
              :icon="colorSchemeIcon"
              fixed-width
            />
          </a>
          <router-link
            :to="{ name: 'About' }"
            class="bg-white p-2 border-app border border-2 shadow rounded hover:outline outline-2 outline-offset-1 dark:bg-gray-700"
          >
            <fa-icon
              icon="fa-solid fa-circle-exclamation"
              fixed-width
            />
          </router-link>
        </div>
      </l-control>
      <MapLookup />
    </l-map>
  </div>
  <div
    v-else
    class="h-full flex justify-center items-center"
  >
    <fa-icon
      icon="fa-solid fa-refresh"
      spin
      size="4x"
      fixed-size
    />
  </div>
  <router-view />
</div>
