
<div id="map-viewer">
  <MapView ref="mapView" />
  <portal-target
    name="details"
    slim
  />
  <portal-target
    name="modal"
    slim
  />
</div>
