
<div class="grid grid-rows-2 grid-cols-1 shadow">
  <button
    class="p-2 rounded-b-none hover:outline outline-2 outline-offset-1 text-white rounded flex flex-cols justify-center dark:text-gray-800"
    :class="zoomInClass"
    @click="zoomIn"
  >
    <fa-icon
      icon="fa-solid fa-plus"
      size="xl"
      fixed-width
    />
  </button>
  <button
    class="p-2 rounded-t-none outline-2 outline-offset-1 text-white rounded flex flex-cols justify-center dark:text-gray-800"
    :class="zoomOutClass"
    @click="zoomOut"
  >
    <fa-icon
      icon="fa-solid fa-minus"
      size="xl"
      fixed-width
    />
  </button>
</div>
